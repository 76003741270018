import { IServicePageResponse } from '../../../api.interface'

export default <IServicePageResponse>{
  serviceType: 'wellness',
  formId: 'DK_CATEGORY_WELLNESS_WIDE',
  title: 'Wellness',
  subTitle: 'Skat, kan du lige massere mine skuldre?',
  icon: '/icons/wellness.svg',
  image: '/assets/images/sunrise/wellness_supermercat.png',
  theme: 'sunrise-wellness',
  sections: [
    {
      sectionId: 'hero',
      label: 'Wellness',
      title: 'Skat, kan du lige massere mine skuldre?',
      description:
        'Hvorfor ikke forebygge de ømme skuldre og den trætte ryg med noget yoga eller pilates -  og mindfullness eller meditation til det trætte hoved? Vore partnere har en masse forslag til, hvordan I får mere velvære i hverdagen.',
      image: '/assets/images/sunrise/wellness_supermercat.png',
      icon: '/icons/wellness.svg',
      buttonText: 'Få et tilbud',
    },
    {
      sectionId: 'options',
      title: 'Der skal blod, sved og tårer til. Eller skal der?',
      description: '',
      optionsItems: [
        {
          icon: '/assets/images/services/wellness/service_1.svg',
          title: 'Meditation',
          description: '',
        },
        {
          icon: '/assets/images/services/wellness/service_2.svg',
          title: 'Yoga',
          description: '',
        },
        {
          icon: '/assets/images/services/wellness/service_3.svg',
          title: 'Workshops',
          description: '',
        },
        {
          icon: '/assets/images/services/wellness/service_4.svg',
          title: 'Foredrag',
          description: '',
        },
      ],
    },
    {
      sectionId: 'journey',
      title: 'Vejen til velvære',
      description: '',
      journeyItems: [
        {
          icon: '/assets/images/services/wellness/journey_1.svg',
          title: 'Fortæl os hvad I mangler',
          description: 'Jo bedre vi forstår, hvad I gerne vil, jo bedre kan vi hjælpe.',
        },
        {
          icon: '/assets/images/services/wellness/journey_2.svg',
          title: 'Få et tilbud',
          description: 'Vi laver et detaljeret tilbud. Ingen overraskelser.',
        },
        {
          icon: '/assets/images/services/wellness/journey_3.svg',
          title: 'Ja tak!',
          description: 'Det var det hele! Nu tager du dig rigtig godt af dine medarbejdere.',
        },
        {
          icon: '/assets/images/services/wellness/journey_4.svg',
          title: 'Kom i form',
          description: 'Balance, energi, velvære. Aaah!',
        },
      ],
    },
    {
      sectionId: 'benefits',
      title: 'Hvorfor elsker vore kunder at komme i form med Good Monday?',
      description: '',
      image: '/assets/images/services/wellness/benefits_illustration.svg',
      benefitsItems: [
        {
          icon: 'eva eva-people-outline',
          title: 'Bedste partnere der findes!',
          description: 'Vi har fundet de bedste partnere til jer',
        },
        {
          icon: 'eva eva-bulb-outline',
          title: 'Inspiration',
          description: 'Oplev en masse nye måder at få sunde og glade medarbejdere på.',
        },
        {
          icon: 'eva eva-video-outline',
          title: 'Både online of fysisk',
          description: 'I bestemmer selv, om vi skal rykke ud til jer, eller om vi skal ses på skærmen.',
        },
      ],
    },
    {
      sectionId: 'cta',
      title: 'Vidste du, at 3 ud af 4 medarbejdere gerne vil have wellness på arbejdet?',
      description: '',
      image: '/assets/images/services/cta_illustration.svg',
      buttonText: 'Få et tilbud',
    },
  ],
}
